/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bool } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    spinnerContainer: {
        width: '100%',
    },
    spinner: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        top: '50%',
        left: '50%',
        zIndex: '10000',
    },
}));

const CalendarLoadingSpinner = ({ spinnerActive }) => {
    const classes = useStyles();
    return (
        <>
            {spinnerActive && (
                <div className={classes.spinnerContainer}>
                    <CircularProgress
                        className={classes.spinner}
                    />
                </div>
            )}
        </>
    );
};

CalendarLoadingSpinner.propTypes = {
    spinnerActive: bool.isRequired,
};

export default CalendarLoadingSpinner;
