/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @param {string} brand - baskets or berries
 * @param {string} productType - gpt or fpt
 * @param {string} userType - passport or non passport
 */
const findCalendar = (weatherBannerQuery = '') => {
    if (weatherBannerQuery) {
        return (
            // very important for serializing. add space between bracket and query name + add typenames to query
            gql`
                query PDPCalendarQuery($productType: String!, $userType: String!, $brand: String!, $environment: String!) {
                findCalendar(brand: $brand, environment: $environment, productType: $productType, userType: $userType)
                {
                    content
                },
                findContent(brand: $brand, environment: $environment, contentType:"inclement_weather_banner", query:"${weatherBannerQuery}"){
                    content
                }
            }`
        );
    }
    return (gql`
        query PDPCalendarQuery($productType: String!, $userType: String!, $brand: String!, $environment: String!) {
            findCalendar(brand: $brand, environment: $environment, productType: $productType, userType: $userType)
            {
                content
            }
        }`
    );
};

export default findCalendar;
