/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const mapBrandCodesToPrefix = {
    10201: 'tpf_',
    10202: 'cco_',
};

export const checkDateHasBackUpProduct = (productCode, backUpProductBrandCode, backUpProductSkuCode) => {
    // backup product (productCode) is returned part of calendar response for each date
    // if the selected delivery date as a backup product, backup product should be used for delivery

    if (backUpProductBrandCode && backUpProductSkuCode) {
        let backUpProductCode = '';
        const brandCode = `${backUpProductBrandCode}`;
        if (Object.keys(mapBrandCodesToPrefix).includes(brandCode)) { // brand TPF and CCO
            backUpProductCode = `${mapBrandCodesToPrefix[brandCode]}${backUpProductSkuCode}`;
        } else { // other brands
            backUpProductCode = `${brandCode}-I-${backUpProductSkuCode}`;
        }

        // if productCode returned from delivery calendar response is different from the product selected on pdp page
        // use the productCode from delivery calender response as the backup product

        if (productCode !== backUpProductCode) {
            return {
                hasProduct: true,
                productCode: backUpProductCode,
            };
        }
    }

    return {
        hasProduct: false,
        productCode,
    };
};

export default {};
