/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    bool, string, func, object, shape, number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        padding: '20px',
        textAlign: 'center',
    },
    okayButton: {
        backgroundColor: theme.palette.colorPrimary,
        color: 'white',
        marginLeft: '9px',
    },
    cancelButton: {
        color: '#65388b',
        border: '1px solid rgba(101, 56, 139, 0.5)',
        marginRight: '9px',
    },
    buttonContainer: {
        marginTop: '15px',
        justifyContent: 'center',
    },
}));

const UnavailableDateModal = ({
    isOpen, messaging, selectDate, addToCartDate, onClose, fakeDateInfo, userSubmittedZip, deliveryType,
}) => {
    const classes = useStyles();
    const formattedDate = addToCartDate.deliveryDate?.split('-')?.join('/');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(trackEvent({
            eventCategory: 'Product Page',
            eventAction: `Fake Flex Shown - ${formattedDate}`,
            eventLabel: `${deliveryType} | ${userSubmittedZip}`,
        }));
    }, [dispatch, userSubmittedZip, deliveryType]);

    const trackFakeFlexModalNavigationButton = (iscontinueBtn) => {
        const flexActionValue =  iscontinueBtn ? `Fake Flex Continue - ${formattedDate}` : `Fake Flex Go Back - ${formattedDate}`;
        dispatch(trackEvent({
            eventCategory: 'Product Page',
            eventAction: flexActionValue,
            eventLabel: `${fakeDateInfo?.flex_option_id} | ${fakeDateInfo?.is_surcharge_override_enabled ? fakeDateInfo?.surcharge : ''}  ${deliveryType} | ${userSubmittedZip}`,
        }));
    };

    return (
        <Dialog className={classes.outerDialog} open={isOpen} style={{ zIndex: '20000' }}>
            <Grid container direction="column" className={classes.outerContainer}>
                <Grid item className={classes.textContainer}>
                    <ReactMarkdown
                        className={classes.text}
                        escapeHtml={false}
                        source={messaging}
                    />
                </Grid>
                <Grid item container className={classes.buttonContainer}>
                    <Grid item className={classes.cancelButton}>
                        <Button data-testid="onBack" variant="container" className={classes.closeButton} onClick={() => { trackFakeFlexModalNavigationButton(false); onClose(); }}>
                            GO BACK
                        </Button>
                    </Grid>
                    <Grid item className={classes.okayButton}>
                        <Button data-testid="onContinue" variant="container" className={classes.okayButton} onClick={() => { trackFakeFlexModalNavigationButton(true); selectDate({ data: addToCartDate, date: new Date(formattedDate) }); }}>
                            CONTINUE
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Dialog>
    );
};

UnavailableDateModal.propTypes = {
    isOpen: bool.isRequired,
    messaging: string.isRequired,
    onClose: func.isRequired,
    selectDate: func.isRequired,
    addToCartDate: object.isRequired,
    userSubmittedZip: string.isRequired,
    deliveryType: string.isRequired,
    fakeDateInfo: shape({
        add_to_cart_date: string.isRequired,
        fake_available_date: string.isRequired,
        flex_date: string,
        flex_forceddeldate: string,
        flex_option_id: string,
        is_surcharge_override_enabled: bool.isRequired,
        messaging: string.isRequired,
        surcharge: number,
    }).isRequired,
};

export default UnavailableDateModal;
