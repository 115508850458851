/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const browserAgnosticNewDate = (dateString) => {
    let date = new Date(dateString);

    if (date && dateString.indexOf('-') > 0 && date.toString() === 'Invalid Date') {
        const withSlash = dateString.replace(/-/g, '/');
        date = new Date(withSlash);
    } else if (date && dateString.indexOf('/') > 0 && date.toString() === 'Invalid Date') {
        const withDash = dateString.replace(/\//g, '-');
        date = new Date(withDash);
    }

    return date;
};

export default browserAgnosticNewDate;
