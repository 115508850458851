/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, array, bool, string, node,
} from 'prop-types';
import ServiceLabel from './ServiceLabel';

const CalendarModalBottomDiv = ({
    brandId,
    classes,
    serviceLevels,
    deliveryImage,
    surcharge,
    isDesktop,
    shippingMessage,
}) => (
    <div className={classes.mobBottomDiv}>
        <div className={`${classes.mobBottomLeft} mobBottomLeft ${isDesktop ? 'mobBottomLeftV2' : ''}`}>
            {shippingMessage}
            <ServiceLabel serviceLevels={serviceLevels} isDesktop={isDesktop} />
            {surcharge && isDesktop && (
                <div
                    className={classes.surchargeMessageV2}
                    style={{ color: brandId === '1034' ? '#0E4728' : '#52297E' }}
                >
                    Selection: {surcharge}
                </div>
            )}
        </div>
        <div className={`${classes.mobBottomRight} mobBottomRight  ${isDesktop ? 'mobBottomRightV2' : ''}`}>
            {' '}
            <img alt="Delivery" src={deliveryImage} />
        </div>
    </div>
);

CalendarModalBottomDiv.propTypes = {
    classes: object.isRequired,
    serviceLevels: array.isRequired,
    deliveryImage: string.isRequired,
    surcharge: string,
    isDesktop: bool,
    shippingMessage: node.isRequired,
    brandId: string.isRequired,
};

CalendarModalBottomDiv.defaultProps = {
    surcharge: '',
    isDesktop: false,
};

export default CalendarModalBottomDiv;
