/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { isEmpty } from '../../../../../../pages/Account/utils/object';

class AddToCartBuilderObject {
    payload = {
        brandCode: null,
        productCode: null,
        quantity: 1,
    };

    components = {
        addons: [],
        cyo: [],
    };

    subscriptionProps = {};

    itemSubscriptionProps = {};

    productType = null

    attributes = [];

    wineVerify ={};

    personalization ={};

    pmallSummaryInfo={}

    giftList= {};

    #selectedItem = {};

    constructor(
        brand, sku, quantity, cyoItems, addonsItems, productType,
        subscriptionObject, attributes, wineVerify,
        personalization, pmallSummaryInfo, giftList,
        deliveryIndicatorPDP, deliveryMethod, contactId, selectedItem,
        delivery,
    ) {
        let productCode = sku;
        if (productCode?.indexOf('-') >= 0) {
            productCode = productCode.split('-');
            productCode = productCode[productCode.length - 1];
        }

        this.payload = {
            brandCode: brand,
            productCode,
            quantity: Math.floor(Number(quantity)) || 1,
        };

        this.components.cyo = cyoItems || [];
        this.components.addons = addonsItems || [];
        this.itemSubscriptionProps = subscriptionObject;
        this.productType = productType;
        this.attributes = attributes;
        this.wineVerify = wineVerify;
        this.personalization = personalization;
        this.pmallSummaryInfo = pmallSummaryInfo;
        this.giftList = giftList;
        this.delivery = delivery;
        this.deliveryIndicatorPDP = deliveryIndicatorPDP;
        this.deliveryMethod =  deliveryMethod;
        this.contactId =  contactId;
        this.#selectedItem = selectedItem;
        this.buildPayload();
    }

    /**
     *
     * @param {*} value of interval, frequency and type
     * @returns removed . from value if present
     */
    cleanSubscriptionValue = (value) => {
        if (value?.indexOf?.('.') > 0) {
            return value.substring(0, value?.indexOf?.('.'))?.toString?.();
        }
        return value?.toString?.() || null;
    }

    /**
     * Building the list for CYO components or Addons.
     */
    getComponentList = (componentObj, componentType) => componentObj.map((componentItem) => {
        let productCode = componentItem.productCode;
        if (productCode?.indexOf('-') >= 0) {
            productCode = productCode.split('-');
            productCode = productCode[productCode.length - 1];
        }

        const component = ({
            productCode,
            brandCode: componentItem.brandCode,
            quantity: componentItem.quantity,
            componentType,
            collection: false, // TODO: after PWA team need see where go this extra info from content stack product response
        });

        if (this.wineVerify.hasWine && this.wineVerify?.wineValidation?.wineVerify?.birthMonth) {
            const { wineVerify } = this.wineVerify.wineValidation;

            component.wineVerify = {
                birthDate: `${wineVerify.birthMonth}/${wineVerify.birthDay}/${wineVerify.birthYear}`,
                minAge: wineVerify.minAge,
            };
        }

        return component;
    })

    /**
     * This method handle CYO components and Addons.
     */
    setComponents() {
        let componentsListAddon = [];
        let componentListCYO = [];

        if (this.components.addons.length > 0) {
            componentsListAddon = this.getComponentList(this.components.addons, 'ADDON');
        }

        if (this.components.cyo.length > 0) {
            componentListCYO = this.getComponentList(this.components.cyo, 'CYO');
        }

        // Wolfermans products can have CYO and addons at same time
        this.payload.components = [...componentsListAddon, ...componentListCYO];
    }

    /**
     *
     * @param {*} value is Product type
     */
    setLineItemType(value) {
        this.payload.lineItemType = value;
    }

    /**
     *
     * @param {*} name Key/attribute name
     * @param {*} val  Value of attribute
     */
    setAttribute(name, val) {
        this.payload.attributes.push({
            attributeName: name,
            attributeValue: val,
        });
    }

    setPersonalization() {
        if (this.personalization.isPersonalizable && this.personalization.personalizedData?.personalizationType) {
            if (this.personalization.personalizedData?.personalizationType === 'PRODUCT_PERSONALIZATION_GCI') {
                const productPerz = this.personalization.personalizedData.productPersonalization || {};

                this.payload.components = [
                    {
                        productCode: productPerz.productCode,
                        brandCode: productPerz.brandCode,
                        quantity: '1',
                        componentType: 'PERSONALIZATION',
                        collection: false,
                        attributes: [
                            {
                                attributeName: 'action',
                                attributeValue: 'personalizationComplete',
                            },
                            {
                                attributeName: 'key_id',
                                attributeValue: productPerz.key_id,
                            },
                            {
                                attributeName: 'thumbnail',
                                attributeValue: productPerz.thumbnail,
                            },
                            {
                                attributeName: 'coverImage',
                                attributeValue: productPerz.coverImage,
                            },
                            {
                                attributeName: 'text',
                                attributeValue: productPerz.text,
                            },
                        ],
                    },
                ];

                this.payload.personalization = {
                    personalizationType: 'PRODUCT_PERSONALIZATION_GCI',
                    personalizationId: productPerz.key_id,
                    personalizationLines: [],
                };
            } else {
                this.payload.personalization = this.personalization.personalizedData;
            }
        }
    }

    /**
     * Setting subscription data to payload
     */
    setSubscription() {
        if (this.subscriptionProps.duration && this.subscriptionProps.type && this.subscriptionProps.interval) {
            this.payload.subscription = {
                duration: this.subscriptionProps.duration,
                interval: this.subscriptionProps.interval,
                type: this.subscriptionProps.type,
            };
        }
    }

    /**
     *
     * @param {*} propType key of subscription
     * @param {*} val value of subscription
     * @returns return data for those keys with clean the string
     */
    subscriptionHandle = (propType, val) => {
        const props =  {
            interval: (value) => {
                this.subscriptionProps.interval = this.cleanSubscriptionValue(value);
            },
            duration: (value) => {
                this.subscriptionProps.duration = this.cleanSubscriptionValue(value);
            },
            type: (value) => {
                this.subscriptionProps.type = this.cleanSubscriptionValue(value);
            },
        };
        return props?.[propType]?.(val);
    }

    /**
     * Setting wine verify data
     */
    setWineVerify() {
        if (this.wineVerify.hasWine && this.wineVerify.wineValidation?.wineVerify) {
            const wineVerify = this.wineVerify.wineValidation.wineVerify;

            this.payload.wineVerify = {
                birthDate: `${wineVerify.birthMonth}/${wineVerify.birthDay}/${wineVerify.birthYear}`,
                minAge: wineVerify.minAge,
            };
        }
    }

    /**
     * Setting price rule id if available
     */

    setDynamicPriceRule() {
        if (this.#selectedItem?.priceRules && this.#selectedItem?.priceRules?.length) {
            this.payload.priceRules = this.#selectedItem.priceRules;
        }
    }

    /**
     * Setting gift list
     * TODO:: we can clean/break this section
     */
    setGiftList() {
        const giftList = this.giftList;
        const deliveryDate = giftList?.deliveryDate || '';
        const holidayCode = giftList?.holidayCode || '';
        const locationType = giftList?.address?.location?.name || '';
        const zipCode = giftList?.address?.zipcode || '';
        const greeting = giftList?.greetingMessage;
        const deliveryIndicator = giftList?.deliveryIndicator || '';

        const deliveryIndicatorFlag = deliveryIndicator === 'MO' || deliveryIndicator === 'WO' || this.deliveryIndicatorPDP === 'MO' || this.deliveryIndicatorPDP === 'WO';
        const glProductType = giftList?.productType || this.productType;
        if (!isEmpty(giftList) && giftList?.products) {
            // Build the gift list data
            this.payload.attributes = [
                {
                    attributeName: 'giftHistoryOrderItemId',
                    attributeValue: giftList.orderItemId,
                },
                {
                    attributeName: 'giftHistoryOccassionCode',
                    attributeValue: giftList.occasionId,
                },
                // gift list recipients all products xOrderAttrValues are same so we get first product attributes
                {
                    attributeName: 'giftHistoryPromCode',
                    attributeValue: giftList?.products?.[0]?.xOrderAttrValues?.giftHistoryPromCode,
                },
                {
                    attributeName: 'giftHistoryCustomerNumber',
                    attributeValue: giftList?.products?.[0]?.xOrderAttrValues?.giftHistoryCustomerNumber,
                },
                {
                    attributeName: 'giftHistoryRecipientCustomerNumber',
                    attributeValue: giftList?.products?.[0]?.xOrderAttrValues?.giftHistoryRecipientCustomerNumber,
                },
            ];

            if (giftList.address) {
                this.payload.recipientInfo = {
                    addressId: giftList.AddressBookEntryId,
                    entryId: giftList.AddressBookEntryId,
                    recipient: {
                        firstName: giftList.firstname,
                        lastName: giftList.lastname,
                        address1: giftList.address.street,
                        address2: '',
                        address3: '',
                        addressId: giftList.AddressBookEntryId,
                        city: giftList.address.city,
                        country: giftList.address.country.id === 'US' ? 'USA' : giftList.address.country.id,
                        dpvIndicator: 'U',
                        isAddressUpdate: false,
                        isAddressVerified: false,
                        locationType: giftList.address.location.name,
                        mobileNumber: '2564785863',
                        organizationName: giftList.address.company,
                        phoneNumber: giftList.phoneNumber || '',
                        state: giftList.address.state.id,
                        zipCode: giftList.address.zipcode,
                    },
                };
            }
            // If user came from gift list then over add to cart these objects fire
            // DO NOT include delivery data for the CLUB, monthly or weekly (MO, WO) product
            if (glProductType !== 'CLUB' && !deliveryIndicatorFlag && ((deliveryDate && holidayCode && this.deliveryMethod) || deliveryDate)) {
                this.setPayload('delivery', {
                    deliveryDate,
                    zipCode,
                    locationType,
                    deliverySLA: this.deliveryMethod,
                    holidayCode,
                });
            }

            if (greeting) {
                this.setPayload('greeting', {
                    message: greeting,
                });
            }
        }
    }

    /**
     * Pmall brand summary
     */
    setPMallSummary() {
        /**
         * Handle req object personalization fields
         * for external products ie. pmall
         *
         * this can be done by just checking for the presence
         * of a pmallSummaryInfo?.customizations.refnum
         * Get the attributes description and preview from the summary api.
         */
        if (this.pmallSummaryInfo?.customizations?.refnum) {
            const personalizationLines = [
                {
                    perAttributeId: 'description',
                    perAttributeValue: this.pmallSummaryInfo.customizations.description,
                },
                {
                    perAttributeId: 'previewUrl',
                    perAttributeValue: this.pmallSummaryInfo.customizations.imageURL,
                },
            ];

            this.payload.personalization = {
                personalizationType: 'EXTERNAL',
                personalizationId: this.pmallSummaryInfo.customizations.refnum,
                personalizationLines,
            };

            if (this.pmallSummaryInfo?.customizations?.addonCount) {
                this.payload.personalization.addonCount = this.pmallSummaryInfo.customizations.addonCount;
            }
        }
    }

    /**
     *
     * @returns Return the payload that we are sending to ATC API
     */
    getPayload() {
        return this.payload;
    }

    setPayload(keyName, value) {
        // TODO:: we can remove this function in future and move every property set in this class
        this.payload[keyName] = value;
    }

    buildPayload = () => {
        // handling Line item
        if (['CLUB', 'COLLECTION', 'NBCU'].includes(this.productType)) {
            this.setLineItemType(this.productType);
        }

        // handling subscription attributes
        if (!isEmpty(this.itemSubscriptionProps) && this.itemSubscriptionProps.isSelected) {
            this.subscriptionHandle('duration', this.itemSubscriptionProps.duration);
            this.subscriptionHandle('interval', this.itemSubscriptionProps.interval);
            this.subscriptionHandle('type', this.itemSubscriptionProps.type);
            this.setSubscription();
        }

        // handling attributes
        if (!isEmpty(this.attributes)) {
            this.payload.attributes = [];// declaring empty list
            Object.keys(this.attributes).forEach((attribute) => {
                if (this.attributes[attribute]) {
                    this.setAttribute(attribute, this.attributes[attribute]);
                }
            });
        }

        // Delivery date info and flex
        if (this.delivery?.deliveryDate) {
            this.payload.delivery = this.delivery;
        }

        // handling components
        this.setComponents();

        // handling wine verify
        this.setWineVerify();

        // handling sPersonalization
        this.setPersonalization();

        // handling pmall summary information
        this.setPMallSummary();

        // Setting price rule id for dynamic price
        this.setDynamicPriceRule();

        this.setGiftList();
    }
}

export default AddToCartBuilderObject;
