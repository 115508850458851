/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, bool, string,
} from 'prop-types';

const UnavailableDateBox = ({
    classes,
    day,
    dateHasIcon,
    dateIconData,
    dateIsFocused,
    ccoCalendarFlag,
    deviceType,
}) => (
    <span
        key={day}
        className={ccoCalendarFlag ? classes.noDateAvailableRedesignV2 : classes.noDateAvailableRedesign}
        style={{
            padding: ccoCalendarFlag && deviceType === 'mobile' ? '5px 0px' : '11px 0px',
            color: ccoCalendarFlag && '#9D9D9D',
            border: deviceType === 'mobile' && ccoCalendarFlag ? 'none' : `${ccoCalendarFlag ? '1px solid #E9E9E9' : '1px solid  #b6b5b5'}`,
            background: ccoCalendarFlag ? '#FFFFFF' : `linear-gradient(to top right,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            rgba(182,181,181) 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%),
            white`,
        }}
    >
        <span key={day} className={classes.notVisible}>
            {dateHasIcon && !dateIsFocused
                ? (
                    <img
                        style={dateIsFocused ? { filter: 'brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1073%) hue-rotate(218deg) brightness(117%) contrast(100%)' } : null}
                        className={`${classes.dateIcon} ${classes.dateIcon}-unavailable`}
                        src={dateIconData?.iconData?.url}
                        alt={dateIconData?.iconData?.name}
                    />
                )
                : null}
            {' '}
            {day}
        </span>
    </span>
);

UnavailableDateBox.propTypes = {
    classes: object.isRequired,
    day: string.isRequired,
    dateHasIcon: bool.isRequired,
    dateIconData: string.isRequired,
    dateIsFocused: bool.isRequired,
    ccoCalendarFlag: bool.isRequired,
    deviceType: string.isRequired,
};

export default UnavailableDateBox;
