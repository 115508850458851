/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    object, func, string,
} from 'prop-types';
import {
    format,
} from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import ReactMarkdown from 'react-markdown';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles((theme) => ({
    radioButtonContainer: {
        margin: '20px auto 0px auto !important',
        borderRadius: '6px !important',
        display: 'flex',
        justifyContent: 'space-around',
        cursor: 'pointer',
        border: '1px solid transparent',
        [theme.breakpoints.down(500)]: {
            width: '100% !important',
        },
        [theme.breakpoints.down(376)]: {
            width: '100% !important',
        },
        // '&:hover': {
        //     border: '1px solid #52297E',
        // },
    },
    radioButtonContainerSelected: {
        // border: '1px solid #52297E',
    },
    radioButton: {
        // native input radio button needs to be present but not visible for custom radio button to work
        display: 'none',
    },
    customRadioButton: {
        accentColor: 'black',
        margin: 'auto 5px',
        '@media not all and (min-resolution:.001dpcm)': { // safari only solution
            '@media': {
                width: '25px',
                height: '25px',
                margin: 'auto 20px auto 0px',
            },
        },
        [theme.breakpoints.down(450)]: {
            '@media not all and (min-resolution:.001dpcm)': { // safari only solution
                '@media': {
                    width: '25px',
                    height: '25px',
                    margin: 'auto 20px auto 0px',
                },
            },
        },
    },
    radioButtonLeftDiv: {
        paddingTop: '10px',
        margin: 'auto 15px',
        height: '439px',
        '& p': {
            margin: '0px',
            fontStyle: 'normal',
            [theme.breakpoints.down(415)]: {
                fontSize: '15px',
            },
            [theme.breakpoints.down(376)]: {
                fontSize: '14px',
            },
        },
    },
    radioButtonRightDivText: {
        textAlign: 'right',
        margin: '20px 15px 20px 0px',
        '&:hover': {
            textAlign: 'right',
            margin: '20px 15px 20px 0px',
        },
        [theme.breakpoints.down(415)]: {
            fontSize: '15px',
            // width added for safari fix
            width: '162px',
        },
        [theme.breakpoints.down(376)]: {
            fontSize: '14px',
            margin: '20px 10px 20px 0px',

        },
    },
    bestValueBanner: {
        textAlign: 'center',
        borderRadius: '6px !important',
        zIndex: '1',
        fontSize: '14px',
        left: '109px',
        top: '12px',
        marginRight: '5px !important',
        position: 'absolute',
        padding: '5px 9px',
        [theme.breakpoints.down(650)]: {
            '@media not all and (min-resolution:.001dpcm)': { // safari only solution
                '@media': {
                    left: '55px',

                },
            },
            left: '110px',
        },
        [theme.breakpoints.down(550)]: {
            left: '35px',
        },
        [theme.breakpoints.down(395)]: {
            left: '25px',
        },
    },
    bestValueBannerImage: {
        marginLeft: '3px',
        width: '12px',
    },
    bestValueBannerForTwoOptions: {
        display: 'flex',
        margin: '0px',
    },
    bestValueBannerSingleOption: {
        left: '110px',
        [theme.breakpoints.down(541)]: {
            left: '40px',
        },
        [theme.breakpoints.down(431)]: {
            left: '70px',
        },
        [theme.breakpoints.down(391)]: {
            left: '50px',
        },
        [theme.breakpoints.down(365)]: {
            left: '35px',
        },
    },
    singleShippingOption: {
        width: '365px !important',
        height: '155px !important',
        textAlign: 'center',
        backgroundColor: '#F9F3F9',
        margin: '20px auto 0px auto !important',
        borderRadius: '6px !important',
        border: '1px solid #52297E',
        padding: '30px',
        '& p': {
            fontSize: '18px',
            margin: '0px',
        },
    },
    singleOptionLocalFloristDeliveryBanner: {
        margin: '0px !important',
        width: '0px !important',
        left: '55px',
        [theme.breakpoints.down(426)]: {
            left: '5px',
        },
    },
    radioButtonParentContainer: {
        position: 'relative',
    },
    label: {
        // border: 'black 1px solid',
        width: '487px',
        padding: '20px 45px 20px 0px',
        borderRadius: '6px',
        marginBottom: '24px',
        margin: '24px auto 0px auto !important',
        display: 'flex',
        cursor: 'pointer',
        backgroundColor: '#F9F3F9',
        border: '1px solid transparent',
        '& p': {
            fontSize: '16px',
            fontFamily: 'lato',
            margin: '0px',
            padding: '0px',
            textWrap: 'balance',
        },
        [theme.breakpoints.down(500)]: {
            width: '95% !important',
            padding: '17px 15px 17px 0px',
        },
        [theme.breakpoints.down(376)]: {
            width: '95% !important',
        },
    },
    labelSelected: {
        border: '1px solid #52297E',
        backgroundColor: '#FFFFFF',
    },
    radioButtonColor: {
        padding: '12px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '&$checked': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    floristRadioButtonColor: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '&$checked': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            backgroundColor: 'green',
        },
    },
    checked: {},
    hideBtn: {
        visibility: 'hidden',
    },
}));

const FlexibleShippingRadioButtons = ({
    flexData, cmsData, handleDateChange, deviceType, selectedDate,
}) => {
    const [value, setValue] = useState(flexData?.[0]?.optionCode);
    const classes = useStyles();
    // For Firefox browser only
    // date formats need to be converted to yyyy/MM/dd instead of yyyy-MM-dd in order to work in Firefox browser
    const convertDateForFF = (date) => {
        let str = date;
        str = str.replace(/-/g, '/');  // replaces all occurances of "-" with "/"
        const dateObject = new Date(str);
        return dateObject;
    };
    const browser = navigator.userAgent;
    const isBrowserFireFox = browser.indexOf('Firefox') > -1;
    const ffFlexDate = convertDateForFF(flexData?.[0]?.flexDate);
    const ffForcedDelDate = convertDateForFF(flexData?.[0]?.forcedDelDate);
    // End of Firefox browser data

    const displayMonth = (month) => {
        if (month.length > 4 && deviceType === 'mobile') {
            return month.slice(0, 3);
        }
        return month;
    };

    const flexDate = isBrowserFireFox ? ffFlexDate : new Date(flexData?.[0]?.flexDate);
    const forcedDelDate = isBrowserFireFox ? ffForcedDelDate : new Date(flexData?.[0]?.forcedDelDate);
    const flexDateFormatted = format(flexDate, 'yyyy-MM-dd');
    const flexDateOptionTwo = flexData?.length === 2 ? new Date(flexData?.[1]?.flexDate) : new Date(flexData?.[0]?.flexDate);
    const fireFoxFlexOptionTwo = flexData?.length === 2 ? convertDateForFF(flexData?.[1]?.flexDate) : convertDateForFF(flexData?.[0]?.flexDate);
    const secondOptionFlexDateFormatted = isBrowserFireFox ? format(fireFoxFlexOptionTwo, 'yyyy-MM-dd') : format(flexDateOptionTwo, 'yyyy-MM-dd');
    const forcedDelDateMonth = format(forcedDelDate, 'MMMM');
    const forcedDelDateDay = format(forcedDelDate, 'do');
    const flexDateDay = format(flexDate, 'do');
    const flexBadge = cmsData?.entries?.[0]?.flex_badge || [];
    const badgeName = flexBadge?.find((badge) =>  badge?.flex_date === secondOptionFlexDateFormatted)?.copy || 'Best Value';
    const badgeIcon = flexBadge?.find((badge) => badge?.flex_date === secondOptionFlexDateFormatted)?.icon?.url || 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt2bdb1ca2caa3d4a5/63c6e090897bff1483453c9c/Sparkles_Icon.svg';
    const deliveryBetweenDate = forcedDelDateDay < flexDateDay ? `${displayMonth(forcedDelDateMonth)} ${forcedDelDateDay} - ${flexDateDay}` : `${displayMonth(forcedDelDateMonth)} ${flexDateDay} - ${forcedDelDateDay}`;
    const showBestValueBanner = cmsData?.entries?.[0]?.show_flex_badge;

    const handleChange = (event) => setValue(event.target.value);

    const renderBestValueBanner = () => (
        <span
            style={{
                background: flexBadge?.find((badge) => badge?.flex_date === secondOptionFlexDateFormatted)?.styles?.background_color?.color || '#FFF2E1',
                color: flexBadge?.find((badge) => badge?.flex_date === secondOptionFlexDateFormatted)?.styles?.copy_color?.color || '#000000',
            }}
            className={flexData?.length === 1 ? `${classes.bestValueBannerSingleOption} ${classes.bestValueBanner}` : classes.bestValueBanner}
        >
            {badgeName}
            <img className={classes.bestValueBannerImage} src={badgeIcon} alt="badge_icon" />
        </span>
    );

    return (
        <>
            {flexBadge?.find((banner) => deliveryBetweenDate === banner.key_name) && flexBadge?.find((date) => flexDateFormatted === date.flex_date) && (renderBestValueBanner())}
            <div className={value === flexData?.[0]?.optionCode ? `${classes.radioButtonContainer} ${classes.radioButtonContainerSelected}` : classes.radioButtonContainer}>
                {showBestValueBanner && flexData.length  !== 1 && renderBestValueBanner()}
                <FormControl component="fieldset" className={classes.flexOptionContainer}>
                    <RadioGroup name="flexSelect" onChange={handleDateChange}>
                        {flexData.map((flexDay) => (
                            <FormControlLabel
                                value={flexDay.optionCode}
                                className={value === flexDay.optionCode ? `${classes.label} ${classes.labelSelected}` : classes.label}
                                control={<Radio classes={{ root: flexData.length  === 1 ? classes.hideBtn : classes.radioButtonColor, checked: classes.checked }} onChange={handleChange} />}
                                label={(
                                    <ReactMarkdown
                                        className={`${flexData.length === 1 && classes.windowContentPadding} ${classes.windowContent}`}
                                        escapeHtml={false}
                                        source={flexDay.flexMsg?.find((message) => message.msgTypeLocation === 'Product')?.msgText}
                                    />
                                )}
                                labelPlacement="end"
                                key={flexDay.optionCode}
                                checked={selectedDate?.optionCode === flexDay?.optionCode}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        </>
    );
};

FlexibleShippingRadioButtons.propTypes = {
    flexData: object.isRequired,
    cmsData: object.isRequired,
    handleDateChange: func.isRequired,
    deviceType: string.isRequired,
    selectedDate: object.isRequired,
};

export default FlexibleShippingRadioButtons;
