/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, func, number, array, bool, string,
} from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    WWW_WEEKDAY_SHORTNAMES_MON_START,
    WWW_WEEKDAY_SHORTNAMES_SUN_START,
} from './Helper/helper';

const MonthTitles = ({
    brandId,
    classes,
    monthName,
    year,
    startDayOfWeek,
    nextMonth,
    preMonth,
    checkCurrentMonth,
    prevBtnCheck,
    deliveryDatesInNextMonth,
    isFinalDateDisplayed,
    calendar,
    ccoCalendarFlag,
    deviceType,
}) => {
    const handleIcon = () => {
        if (ccoCalendarFlag) {
            if (deviceType === 'mobile') {
                return (
                    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L11 9L1 17" stroke="#7B7B7B" stroke-width="2" stroke-linecap="round" />
                    </svg>
                );
            }
            return <ArrowForwardIcon />;
        }
        return 'NEXT';
    };
    const handlePrevIcon = () => {
        if (ccoCalendarFlag) {
            if (deviceType === 'mobile') {
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none">
                        <path d="M12 1L2 9L12 17" stroke="#7B7B7B" stroke-width="2" stroke-linecap="round" />
                    </svg>
                );
            }
            return <ArrowBackIcon />;
        }
        return 'PREV';
    };
    const prevBtn = checkCurrentMonth && prevBtnCheck ? (
        <span
            role="presentation"
            onClick={() => {
                preMonth();
            }}
            className={ccoCalendarFlag ? classes.prevArrowBtn : classes.prevBtn}
            style={{ color: brandId === '1034' ? '#0E4728' : '#52297E' }}
        >
            {handlePrevIcon()}
        </span>
    ) : null;
    const nextBtn = (checkCurrentMonth && (deliveryDatesInNextMonth || isFinalDateDisplayed(calendar))) && (
        <span
            role="presentation"
            onClick={() => {
                nextMonth();
            }}
            className={ccoCalendarFlag ? classes.nextArrowBtn : classes.nextBtn}
            style={{ color: brandId === '1034' ? '#0E4728' : '#52297E' }}
        >
            {handleIcon()}
        </span>
    );
    const weekShortNameArr = startDayOfWeek === 0 ? WWW_WEEKDAY_SHORTNAMES_SUN_START : WWW_WEEKDAY_SHORTNAMES_MON_START;
    return (
        <div className={classes.weekDayName}>
            <div className={ccoCalendarFlag ? classes.monthTitleV2 : `${classes.monthTitle} monthTitle`}>
                {prevBtn}
                {monthName} {year}
                {nextBtn}
            </div>
            {weekShortNameArr.map((name) => (
                <span key={name} className={ccoCalendarFlag ? classes.weekDayV2 : classes.weekDay}>
                    {deviceType === 'mobile' && ccoCalendarFlag ? name.slice(0, 1) : name}
                </span>
            ))}
        </div>
    );
};

MonthTitles.propTypes = {
    brandId: string.isRequired,
    classes: object.isRequired,
    monthName: array.isRequired,
    year: number.isRequired,
    startDayOfWeek: number.isRequired,
    nextMonth: func.isRequired,
    preMonth: func.isRequired,
    isFinalDateDisplayed: func.isRequired,
    calendar: array.isRequired,
    prevBtnCheck: bool.isRequired,
    checkCurrentMonth: bool.isRequired,
    deliveryDatesInNextMonth: bool.isRequired,
    ccoCalendarFlag: bool.isRequired,
    deviceType: string.isRequired,
};

export default MonthTitles;
