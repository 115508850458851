/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    func, string, bool, shape, array, number, arrayOf,
} from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import mbpLogger from 'mbp-logger';
import { useFlags } from 'launchdarkly-react-client-sdk';
import memberDucks from '../../../../../../state/ducks/Member/ducks';
import findCalendar from '../../../../../gql/queries/findCalendar';

import GraphqlPDPCalendar from './GraphqlPDPCalendar';
import { GRAPHQL_ENV } from '../../../../../gql';
import getBrandDomainById from '../../../../../helpers/getBrandDomain';
import noop from '../../../../../helpers/noop';
import { getUserState } from '../../../../../../state/ducks/App/App-Selectors';
import { getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { CrossMerch } from '../../../CrossMerchContainer/CrossMerchContainer';

const {
    common: {
        commonSelectors: { getUserRole },
    },
} = memberDucks;

const GraphqlCalendarContainer = ({
    close,
    isOpen,
    selectedItemId,
    productDeliveryType,
    brandId,
    userRole,
    setCalendarDataLoadingFlag,
    handleDeliveryAvailability,
    orderItemId,
    ageDetails,
    partNumber,
    todayTomDateNotAvailText,
    sympathyValue,
    pageType,
    zipCode,
    breadCrumbArray,
    productName,
    isInternationalProduct,
    internationalCountry,
    isPassportBundleItem,
    personalization,
    movieSelected,
    isPassportBundleProduct,
    selectedSku,
    hasWine,
    isFlowersSubscriptionFeatureEnabled,
    subscription,
    subscriptionDetails,
    isFlowersTempV1SubscriptionFeatureEnabled,
    handleMiniCartModalClick,
    enableMinicart,

    isQuickviewOrigin, // category page quickview
    quickviewSelectDate, // category page quickview
    pmallData,
    subscriptionTypeAttribute,
    showWrapup,
    quickViewLocationType,
    productSkus,
    categoryId,
    categoryPath,
    categoryName,
    displayDeliveryCalendar,
}) => {
    let userType;
    if (userRole === 'P') {
        userType = 'passport';
    } else {
        userType = 'guest';
    }
    const brandDomain = getBrandDomainById(brandId); // get product's brand domain in case product not from current brand (so we can display appropriate calendar)
    const stateValue = useSelector((state) => getUserState(state));
    const { featureFlags: productBrandsFeatureFlags = {} } = useContext(CrossMerch);
    const currentBrandFeatureFlags = useSelector(getFeatureFlags);
    const featureFlags = Object.keys(productBrandsFeatureFlags)?.length > 0 ? productBrandsFeatureFlags : currentBrandFeatureFlags;
    const isInclementBannerEnabled = featureFlags['is-calendar-inclement-weather-banner-enabled'];
    const flags = useFlags();
    const weatherBannerQuery = isInclementBannerEnabled ? `{\\"$or\\": [{ \\"state\\": \\"\\" }, { \\"state\\": {\\"$in\\":[\\"${stateValue}\\"]} }]}` : null;
    const FIND_CALENDAR_QUERY = findCalendar(weatherBannerQuery);
    const [getCalendar, {
        error, data,
    }] = useLazyQuery(FIND_CALENDAR_QUERY);

    useEffect(() => {
        getCalendar({
            variables: {
                brand: brandDomain,
                environment: GRAPHQL_ENV,
                productType: productDeliveryType,
                userType,
            },
        });
    }, [userType]);

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FIND_CALENDAR_QUERY,
            component: 'GraphqlCalendarContainer.js',
            message: 'Error loading calendar',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    /**
     * There seems to be many needless re-renders here but returning null
     * if no data causes it to break elsewhere so should be left for now
     */

    return (
        <GraphqlPDPCalendar
            cmsData={data?.findCalendar?.content}
            isOpen={isOpen}
            close={close}
            selectedItemId={selectedItemId}
            setCalendarDataLoadingFlag={setCalendarDataLoadingFlag}
            handleDeliveryAvailability={handleDeliveryAvailability}
            orderItemId={orderItemId}
            deliveryType={productDeliveryType}
            brandId={brandId}
            ageDetails={ageDetails}
            partNumber={partNumber}
            todayTomDateNotAvailText={todayTomDateNotAvailText}
            sympathyValue={sympathyValue}
            pageType={pageType}
            zipCode={zipCode}
            breadCrumbArray={breadCrumbArray}
            productName={productName}
            isInternationalProduct={isInternationalProduct}
            internationalCountry={internationalCountry}
            isPassportBundleItem={isPassportBundleItem}
            personalization={personalization}
            movieSelected={movieSelected}
            isPassportBundleProduct={isPassportBundleProduct || {}}
            selectedSku={selectedSku}
            hasWine={hasWine}
            isFlowersSubscriptionFeatureEnabled={isFlowersSubscriptionFeatureEnabled}
            isFlowersTempV1SubscriptionFeatureEnabled={isFlowersTempV1SubscriptionFeatureEnabled}
            subscription={subscription}
            subscriptionDetails={subscriptionDetails}
            handleMiniCartModalClick={handleMiniCartModalClick}
            enableMinicart={enableMinicart}

            quickviewSelectDate={quickviewSelectDate} // category page quickview
            isQuickviewOrigin={isQuickviewOrigin} // category page quickview
            pmallData={pmallData}
            subscriptionTypeAttribute={subscriptionTypeAttribute}
            showWrapup={showWrapup}
            quickViewLocationType={quickViewLocationType}
            productSkus={productSkus}
            featureFlags={featureFlags}
            attributeCategoryId={categoryId}
            attributeCategoryPath={categoryPath}
            attributeCategoryName={categoryName}
            inclementWeatherBanner={data?.findContent?.content?.entries?.[0] || null}
            ldFlag={flags}
            displayDeliveryCalendar={displayDeliveryCalendar}
        />
    );
};

const mapStateToProps = (state) => ({
    userRole: getUserRole(state),
});

/**
 * TODO: this section would greatly benefit if
 * props were marked or had indication of the intended feature they support.
 */
GraphqlCalendarContainer.propTypes = {
    /**
     * Critical props to render the calendar. Without these,
     * api calls fail and the calendar will not show up on the page.
     */
    /** */ selectedItemId: string.isRequired,
    /** */ zipCode: string,
    /** */ productDeliveryType: string.isRequired,
    /** */ setCalendarDataLoadingFlag: func.isRequired,
    /** */ brandId: string.isRequired,

    /**
     *
     *
     * Required Props
     */
    partNumber: string.isRequired,
    productName: string.isRequired,
    personalization: shape({
        indicator: string,
        lineState: arrayOf(
            shape({
                lineNumber: number.isRequired,
                maxChars: number.isRequired,
            }).isRequired,
        ),
        modalOpen: bool.isRequired,
    }).isRequired,
    selectedSku: shape({
        prices: arrayOf(shape({
            type: string,
            value: number,
        })),
    }).isRequired,
    isFlowersSubscriptionFeatureEnabled: bool.isRequired,
    isFlowersTempV1SubscriptionFeatureEnabled: bool.isRequired,

    enableMinicart: bool.isRequired,
    handleMiniCartModalClick: func.isRequired,

    close: func.isRequired,
    isOpen: bool.isRequired,
    userRole: string.isRequired,
    handleDeliveryAvailability: func.isRequired,
    ageDetails: shape({
        birthDay: string,
        birthMonth: string,
        birthYear: string,
        minAge: string,
    }).isRequired,

    /**
     *
     *
     * Optional Props
     */
    orderItemId: string,
    displayDeliveryCalendar: bool.isRequired,
    sympathyValue: string,
    pageType: string,
    isInternationalProduct: bool,
    isPassportBundleItem: bool,
    movieSelected: shape({
        error: bool,
        skuSelected: shape({
            sku: string.isRequired,
            sku_description: string.isRequired,
            sku_price: number.isRequired,
        }),
    }),
    isPassportBundleProduct: shape({
        prices: shape({
            value: number,
        }),
        enable: bool,
    }),
    hasWine: string,
    subscriptionDetails: shape({
        subscriptionType: string,
        subscriptionDuration: string,
        subscriptionFrequency: string,
    }),
    subscription: shape({
        subscriptionKey: array,
        subscriptionIndefinite: string,
        subscriptionType: array,
        implementProductSubscription: bool,
        viewSubscription: bool,
        isTypeDiscovery: bool,
    }),
    internationalCountry: string,
    breadCrumbArray: array,
    todayTomDateNotAvailText: string,

    /**
     * Props pertaining to the Category Quickview Feature
     * @define {isQuckviewOrigin} flag for calendar to know if its used from quickview or not.
     * @define {quickviewSelectDate} function to return the selected date to the quickview component.
     */
    isQuickviewOrigin: bool,
    quickviewSelectDate: func,

    // pmall, all we need for now is refnum
    pmallData: shape({
        refnum: string,
    }),
    subscriptionTypeAttribute: string,
    showWrapup: string,
    quickViewLocationType: string,
    productSkus: array,
    categoryId: string,
    categoryPath: string,
    categoryName: string,
};

GraphqlCalendarContainer.defaultProps = {
    quickViewLocationType: 'Residence',
    orderItemId: '',
    todayTomDateNotAvailText: '',
    pageType: '',
    zipCode: null,
    breadCrumbArray: [],
    isInternationalProduct: null,
    internationalCountry: '',
    isPassportBundleItem: false,
    movieSelected: null,
    isPassportBundleProduct: {},
    hasWine: 'false',
    subscriptionDetails: {},
    subscription: {},
    sympathyValue: null,

    isQuickviewOrigin: false,
    quickviewSelectDate: noop,
    // pmall
    pmallData: { refnum: '' },
    subscriptionTypeAttribute: null,
    showWrapup: 'true',
    productSkus: [],
    categoryId: '',
    categoryPath: '',
    categoryName: '',
};

export default connect(
    mapStateToProps,
    null,
)(GraphqlCalendarContainer);
