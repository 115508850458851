/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
    object, func, bool,
} from 'prop-types';

const CalendarModalHeader = ({
    classes,
    closeAndResetState,
    ccoCalendarFlag,
}) => (
    <div className={`${classes.modalHeaderContainer} ${classes.modelHeaderV2}`}>
        <div className={ccoCalendarFlag ? `${classes.modalHeaderV3} modalHeader ${classes.modelHeaderV2}` : `${classes.modalHeader} modalHeader ${classes.modelHeaderV2}`} style={{ color: '#000000' }}>
            SELECT DELIVERY DATE
            <span
                onClick={closeAndResetState}
                role="presentation"
                className={classes.closeModal}
                id="close-full-calendar"
                data-test="pdp-close-full-calendar"
                name="closeFullCalendar"
            >
                <CloseIcon />
            </span>
        </div>
    </div>
);

CalendarModalHeader.propTypes = {
    classes: object.isRequired,
    closeAndResetState: func.isRequired,
    ccoCalendarFlag: bool.isRequired,
};

export default CalendarModalHeader;
