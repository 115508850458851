/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import brandTheme from '../components/AppShell/brandtheme';

/**
 * Returns brand domain for brand
 * This function uses brandTheme to get the brand domain
 * @param {string} brandId brand id - 1001
 */
const getBrandDomainById = (brandId) => {
    const brandCode = Object.keys(brandTheme).filter((brand) => brandTheme[brand]?.id === brandId)[0];
    // TODO: when goodsey is part of our platform we need remove the restriction
    // also for the products are not in our system yet the empty value will default to 1800flowers
    let brandName = brandTheme[brandCode]?.domain || '';
    if (brandName === '' || brandName === 'goodsey') {
        brandName = '1800flowers';
    }
    return brandName;
};

export default getBrandDomainById;
