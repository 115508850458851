/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { func } from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getCartError, getAddToCartError } from '../../../../../../../state/ducks/Common/Common-Selectors';

const useStyles = makeStyles((theme) => ({
    closeModal: {
        position: 'absolute',
        fontWeight: 'normal',
        right: '12px',
        color: theme.palette.colorNeutral70,
        cursor: 'pointer',
    },
    modelHeader: {
        borderBottom: `2px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        textTransform: 'uppercase',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '15px 0px !important',
    },
    closeModalEmpty: {
        minHeight: 40,
        border: 0,
    },
    cartError: {
        fontSize: 15,
        marginTop: 0,
        textAlign: 'center',
        padding: '5px',
    },
    errorIcon: {
        color: theme.palette.error.main,
        verticalAlign: 'bottom',
    },
}));

const CalendarErrorDialog = ({ closeAndResetState }) => {
    const classes = useStyles();
    const cartError = useSelector(getCartError);
    const addToCartError = useSelector(getAddToCartError);

    return (
        <>
            <div className={`${classes.modelHeader} ${classes.closeModalEmpty}`}>
                <span
                    onClick={closeAndResetState}
                    role="presentation"
                    className={classes.closeModal}
                    id="close-full-calendar"
                    data-test="pdp-close-full-calendar"
                    name="closeFullCalendar"
                >
                    X
                </span>
            </div>
            <DialogTitle>
                <p className={classes.cartError}>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                    {ReactHTMLParser(addToCartError || cartError)}
                </p>
            </DialogTitle>
        </>
    );
};

CalendarErrorDialog.propTypes = {
    closeAndResetState: func.isRequired,
};

export default CalendarErrorDialog;
