/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-nested-ternary */

import React from 'react';
import {
    object, func, bool, string,
} from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {
    Drawer,
} from '@material-ui/core';
import { LeftToRightTransition } from './Helper/helper';

const CalendarModalTransition = ({
    classes,
    addToCartError,
    cartError,
    spinnerActive,
    isOpen,
    RenderCalendar,
    flags,
    deviceType,
    isNewDeliveryCalendarEnabled,
}) => (
    <>
        {flags['is-atc-smooth-transition-enabled'] ? (
            <Dialog
                TransitionComponent={LeftToRightTransition}
                transitionDuration={deviceType === 'mobile' ? 600 : 800}
                TransitionProps={{
                    transitionDirection: 'left',
                }}
                className={isNewDeliveryCalendarEnabled ? `${classes.modalContainerV2} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}` : `${classes.modalContainer} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}`}
                open={isOpen}
                classes={spinnerActive ? { paper: classes.loadingFadeOut } : null}
            >
                {RenderCalendar}
            </Dialog>
        ) : (isNewDeliveryCalendarEnabled && deviceType === 'mobile' ? (
            <Drawer
                className={isNewDeliveryCalendarEnabled ? `${classes.modalContainerV2} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}` : `${classes.modalContainer} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}`}
                anchor="bottom"
                open={isOpen}
                classes={spinnerActive ? { paper: classes.loadingFadeOut } : null}
            >
                {RenderCalendar}
            </Drawer>
        ) : (
            <Dialog
                className={isNewDeliveryCalendarEnabled ? `${classes.modalContainerV2} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}` : `${classes.modalContainer} ${((addToCartError || cartError) ? classes.maxClosedWidth : null)}`}
                open={isOpen}
                classes={spinnerActive ? { paper: classes.loadingFadeOut } : null}
            >
                {RenderCalendar}
            </Dialog>
        )
        )}
    </>

);

CalendarModalTransition.propTypes = {
    classes: object.isRequired,
    RenderCalendar: func.isRequired,
    isOpen: bool.isRequired,
    spinnerActive: bool.isRequired,
    addToCartError: string,
    cartError: string,
    flags: object,
    deviceType: string.isRequired,
    isNewDeliveryCalendarEnabled: bool.isRequired,
};

CalendarModalTransition.defaultProps = {
    addToCartError: '',
    cartError: '',
    flags: {},
};

export default CalendarModalTransition;
