/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    addMonths, startOfMonth,
} from 'date-fns';
import { bool } from 'prop-types';
import { Slide } from '@material-ui/core';

export const WWW_WEEKDAY_SHORTNAMES_MON_START = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
];

export const WWW_WEEKDAY_SHORTNAMES_SUN_START = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
];

export const MMMM_MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const MMMM_SHORT_MONTH_NAMES = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const z2fmt = (v) => (v < 10 ? '0' : '') + v; // e.g. '03', '12'

export const formatDateYYYY_MM_DD = (d) => `${d.getFullYear()}-${z2fmt(d.getMonth() + 1)}-${z2fmt(d.getDate())}`; // e.g. '2019-11-01'
export const formatDate_d_MMM_yy = (d) => {
    const dateArr = d.split('-');
    return `${Number(dateArr[2])}-${MMMM_SHORT_MONTH_NAMES[Number(dateArr[1] - 1)]}-${dateArr[0].substring(2, 4)}`; // e.g. '30-Jun-23'
};

export const formatDate_MM_dd_yyyy_HH_mm_ss = (d) => {
    // Example date in parameter: 2023-07-22
    const dateArr = d.split('-');
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]} 00:00:00`; // e.g. '07/22/2023 00:00:00'
};

export const yyyy_mm_ddToDate = (dateString) => {
    // YYYY-MM-DD date string to JS Date.  Made to avoid doing Date('YYYY-MM-DD') because that assumes UTC.
    const m = dateString.match(/^\s*(\d\d\d\d)-(\d\d?)-(\d\d?)\s*$/);
    if (m) {
        return new Date(m[1], m[2] - 1, m[3]);
    }
    return 0;
};

export const getNow = () => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
}; // 12:00 midnight of current day

export const firstOfNextMonth = (d) => addMonths(startOfMonth(new Date(d)), 1);

export const firstofPrevMonth = (d) => {
    const m = d.getMonth();
    return m === 0
        ? new Date(d.getFullYear() - 1, 11, 1)
        : new Date(d.getFullYear(), m - 1, 1);
};

// eslint-disable-next-line react/jsx-props-no-spreading
export const LeftToRightTransition = React.forwardRef((props, ref) => (<Slide direction={props?.transitionDirection || 'left'} timeout={600} ref={ref} {...props} />));

LeftToRightTransition.propTypes = {
    transitionDirection: bool.isRequired,
};

export default {};
