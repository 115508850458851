/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { createPortal } from 'react-dom';

import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { element, string, object } from 'prop-types';

const styles = (theme) => ({
    loaderBackground: {
        '&::before': {
            width: '100%',
            height: '300%',
            content: '""',
            position: 'absolute',
            zIndex: '10000',
            backgroundColor: 'rgba(255,255,255,0.5)',
        },
    },
    spinner: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        position: 'fixed',
        top: '50%',
        left: '50%',
        zIndex: '10001',
    },
});

const GraphqlLoadingDiv = ({ target, classes, elementToRender }) => {
    if (typeof document !== 'undefined') {
        const portalRoot = target
            ? document.getElementById(target)
            : document.getElementById('fullPageLoadingDiv');
        const loaderDiv = (
            <div className={classes.loaderBackground}>
                <CircularProgress className={classes.spinner} />
            </div>
        );
        if (!portalRoot) {
            return null;
        }
        return createPortal(
            elementToRender || loaderDiv,
            portalRoot,
        );
    }

    return null;
};

GraphqlLoadingDiv.propTypes = {
    target: string,
    classes: object.isRequired,
    elementToRender: element,
};

GraphqlLoadingDiv.defaultProps = {
    elementToRender: undefined,
    target: '',
};

export default withStyles(styles)(GraphqlLoadingDiv);
