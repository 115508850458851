/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool,
    object, string,
} from 'prop-types';

const ShippingMessage = ({
    classes,
    shippingMessage,
    desktop,
}) => (
    <div className={`${classes.negativeSurchargeMessage} ${desktop ? classes.negativeSurchargeMessageV2 : ''}`}>
        <p>
            {shippingMessage}
            <span className={classes.negativeSurchargeIcon}>
                <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt2bdb1ca2caa3d4a5/63c6e090897bff1483453c9c/Sparkles_Icon.svg" alt="negative_surcharge_banner" />
            </span>
        </p>
    </div>
);

ShippingMessage.propTypes = {
    classes: object.isRequired,
    shippingMessage: string.isRequired,
    desktop: bool,
};

ShippingMessage.defaultProps = {
    desktop: false,
};

export default ShippingMessage;
