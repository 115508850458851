/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { isWeekend, getDay, compareAsc } from 'date-fns';

const cutOffTimes = {
    weekDay: '14:30',
    weekEnd: {
        saturday: '13:00',
        sunday: '11:30',
    },
};

const determineIsPastCutoff = () => {
    let cutoffTime;
    const currentTime = new Date();
    const isTodayWeekend = isWeekend(currentTime);
    if (isTodayWeekend) {
        if (getDay(currentTime) === 0) {
            cutoffTime = cutOffTimes.weekEnd.sunday.split(':');
        } else {
            cutoffTime = cutOffTimes.weekEnd.saturday.split(':');
        }
    } else {
        cutoffTime = cutOffTimes.weekDay.split(':');
    }
    const todayCutoffTime = new Date();
    todayCutoffTime.setHours(cutoffTime[0]);
    todayCutoffTime.setMinutes(cutoffTime[1]);
    const isPastCutoff = compareAsc(currentTime, todayCutoffTime);
    if (isPastCutoff >= 0) {
        return true;
    }
    return false;
};

export default determineIsPastCutoff;
