/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, shape, string, func,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: '14px',
        color: theme.palette.colorPrimary,
        fontWeight: 400,
    },
    button: {
        marginTop: '10px',
        fontSize: '14px',
        fontWeight: 700,
        [theme.breakpoints.down(480)]: {
            width: '50%',
            marginBottom: '15px',
        },
    },
    divider: {
        [theme.breakpoints.down(600)]: {
            height: 'auto !important',
            position: 'fixed !important',
            bottom: '0px',
        },
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        boxShadow: '3px 0px 3px rgba(47, 47, 47, 0.15)',
    },
    link: {
        textDecorationColor: '#551A8B',
    },
}));

const AltCalendarWidget = ({
    trackEvent,
    altCalendarData: {
        cta_button_text, right_rail_text, collection, cta_button_text_color, right_rail_text_color, alt_calendar_icon,
    },
}) => {
    const classes = useStyles();
    useEffect(() => {
        trackEvent({
            eventCategory: 'Test Impression',
            eventAction: 'Alt Calendar',
            eventLabel: 'PDP - Alt calendar',
            nonInteraction: true,
        });
    }, []);
    return (
        <>
            <Grid className={classes.divider}>
                <Grid
                    item
                    className={classes.textWrapper}
                    style={{ display: 'flex', width: '200px' }}
                >
                    <Typography className={classes.text} style={{ color: right_rail_text_color?.color || '' }}>
                        {right_rail_text}
                    </Typography>
                    {alt_calendar_icon?.url && (
                        <img style={{ margin: '3px 0px 0px 3px', width: '22px' }} src={alt_calendar_icon?.url} alt="alt_calendar_icon" />
                    )}
                </Grid>
                <Grid style={{ width: '94px', top: '-2px' }}>
                    <Link to={collection} className={classes.link}>
                        <span
                            className={classes.button}
                            style={{
                                color: cta_button_text_color?.color || '',
                            }}
                        >
                            {cta_button_text}
                        </span>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};

AltCalendarWidget.propTypes = {
    trackEvent: func.isRequired,
    altCalendarData: shape({
        cta_button_text: string.isRequired,
        right_rail_text: string.isRequired,
        collection: string.isRequired,
        cta_button_text_color: object,
        right_rail_text_color: object,
    }),
};

AltCalendarWidget.defaultProps = {
    altCalendarData: {
        cta_button_text_color: {},
        right_rail_text_color: {},
    },
};

export default AltCalendarWidget;
