/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mobMarkDiv: {
        fontSize: '13px',
        fontWeight: '400',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left !important',
            padding: '1px 0px !important',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px',
            fontWeight: '400',
            padding: '3px 0px 0px 15px !important',
            width: 'initial !important',
            display: 'flex',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: '10px',
            fontWeight: '400',
        },
    },
    rightRailIcon: {
        width: '11%',
        marginRight: '5%',
        [theme.breakpoints.down('sm')]: {
            width: '9%',
            marginRight: '4%',
        },
    },
    serviceBlocks: {
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            padding: '3px 0px 0px 0px !important',
        },
        display: 'flex',
        alignItems: 'center!important',
        alignContent: 'center!important',
        marginBottom: '10px !important',
    },
    deliveryIcon: {
        display: 'inline-block',
        borderRadius: '100%',
        width: '16px',
        height: '16px',
        background: theme.palette.grey10,
        marginRight: '10px',
        verticalAlign: 'text-bottom',
        [theme.breakpoints.down('sm')]: {
            width: '14px',
            height: '14px',
        },
    },
}));

const ServiceLabel = ({ serviceLevels, isDesktop }) => {
    const classes = useStyles();
    if (!serviceLevels || !serviceLevels.length) {
        return null;
    }
    return (
        serviceLevels.map((serviceLevel) => (
            <>
                {((serviceLevel?.name !== 'Holiday Shipping' && serviceLevel?.name !== 'Unavailable Delivery')) && (
                    <div key={serviceLevel?.name}>
                        <div className={`${classes.mobMarkDiv} ${isDesktop ? classes.serviceBlocks : ''}`}>
                            {serviceLevel?.right_rail_icon?.url
                                ? <img src={serviceLevel?.right_rail_icon?.url} alt={serviceLevel?.right_rail_icon?.filename} className={classes.rightRailIcon} />
                                : (
                                    <span
                                        className={classes.deliveryIcon}
                                        style={{
                                            background: (serviceLevel?.name === 'Holiday Shipping' || serviceLevel?.name === 'Unavailable Delivery') ? 'white'
                                                : serviceLevel?.color,
                                        }}
                                    />
                                )}
                            {(serviceLevel?.name === 'Holiday Shipping' || serviceLevel?.name === 'Unavailable Delivery') ? '' : serviceLevel?.name}
                        </div>
                    </div>
                )}
            </>
        ))
    );
};

ServiceLabel.propTypes = {
    serviceLevels: array.isRequired,
    isDesktop: bool.isRequired,

};

export default ServiceLabel;
